/**
 * Helpers
 */
 import {
  fromBreakpoint,
  onVisibilityChange, toBreakpoint
} from './helpers'

/**
 * Web standards
 */
import './cookieconsent'
import './softscroll'
import './countto'
import './lazyload'
import { modal } from './modal'
// import 'alpinejs/dist/cdn.min.js';

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector( '.wpcf7-form' );
if ( wpcf7forms ) {
  const jquery = import( 'jquery' );
  global.jQuery = require( 'jquery' );
  window.jQuery = require( 'jquery' );
}

/**
 * Add scripts or styles based on loaded DOM
 */
addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('div[data-modal-target]').length > 0) {
    modal.init();
  }
})


/**
 * Dynamic import scripts
 */
const elements = [
  '[data-aos]',
  '.acf-map'
];

[].forEach.call(elements, (element) => {
  if (element !== '') {
    if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {
      const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

        if (element === '[data-aos]') {
          const AOS = import('aos').then(AOS => {
            AOS.init({
            disable: function() {
              return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
            }
            });
          });
        }

        if (element === '.acf-map') {
          const googlemap = import('./googlemap');
        }

      })

      if (window.addEventListener) {
        addEventListener('DOMContentLoaded', lazyloadHandler, false);
        addEventListener('load', lazyloadHandler, false);
        addEventListener('scroll', lazyloadHandler, false);
        addEventListener('resize', lazyloadHandler, false);
      } else if (window.attachEvent) {
        attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
        attachEvent('onload', lazyloadHandler);
        attachEvent('onscroll', lazyloadHandler);
        attachEvent('onresize', lazyloadHandler);
      }
    }
  }
});

if (document.getElementById('nav-toggle')) {
  document.getElementById('nav-toggle').addEventListener('click', function () {
    var navMenu = document.getElementById('nav-menu');
    navMenu.classList.toggle('-translate-x-full');
    navMenu.classList.toggle('translate-x-0');
  });
}


var o = $('.animate-count');
var cc = 1;
if (o.length > 0) {
  $(window).on('scroll load', function () {
    var elemPos = o.offset().top;
    var elemPosBottom = o.offset().top + o.height();
    var winHeight = $(window).height();
    var scrollToElem = elemPos - winHeight;
    var winScrollTop = $(window).scrollTop() + 1;
    if (winScrollTop > scrollToElem) {
      if (elemPosBottom > winScrollTop) {
        if (cc < 2) {
          cc = cc + 2;
          $(document).ready(function () {
            o.countTo({
              formatter: function (value, options) {
                value = value.toFixed(options.decimals);
                
                return value;
              }
            });
          });
        }
      }
    }
  });
}
